import React, {useEffect, useState} from 'react'
import "./NewsletterAlert.css"

function NewsletterAlert(props) {

	const [isOpen, setIsOpen] = useState(false)
	const [err, setErr] = useState("")
	const [txtField, setTxtField] = useState("")
	const [succesMailSub, setSuccesMailSub] = useState(false)

	useEffect(() => {
		setIsOpen(props.states.isOpen)
	}, [props])



	const handleValidation = () => {
		console.log(txtField);

		if(!txtField){
			// formIsValid = false
			console.log("Cannot be empty")
			setErr("Cannot be empty")
			return
		}

		if (typeof txtField !== "undefined") {
			let lastAtPos = txtField.lastIndexOf('@')
			let lastDotPos = txtField.lastIndexOf('.')

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && txtField.indexOf('@@') === -1 && lastDotPos > 2 && (txtField.length - lastDotPos) > 2)) {
				// formIsValid = false
				console.log("Email is not valid")
				setErr("Email is not valid")
				return
			}
		} 
		
		//
		let fetchData = {
			method: "POST",
			body: JSON.stringify({email: txtField, js: true}),
			headers: {"Content-Type": "application/json"}
		}

		fetch("http://localhost:4000/mail/subscribe", fetchData)
			.then(res => {
				if (res.ok) {
					console.log("worked!!")
					setSuccesMailSub(true)
				} else {
					setErr("SOMETHING WENT WRONG. TRY LATER AGAIN.")
				}
			}).catch(err => {
				setErr("SOMETHING WENT WRONG. TRY LATER AGAIN.")
			})
	}

	// On Change
	const onTxtFieldChange = (e) => {
		setErr("")
		setTxtField(e.target.value)
	}

	// On KeyPress
	const keyPressed = (event) => {
		if (event.key === "Enter") {
			handleValidation()
		}
	}

	// On Click
	const onCancelClick = () => {
		// setIsOpen(false)
		props.states.setBackToHidden()
		setSuccesMailSub(false)
	}

	const onOverlayClick = (e) => {
		if (e.target.className === "overlay") { onCancelClick() }
	}

	return(
		<div className="Newsletter" style={{display: isOpen ? "flex" : "none"}}>
			<div className="alert">
				<p className="title">NEWSLETTER</p>
				<p className="text">GET NOTIFED WHEN NEW STYLES ARE RELEASED</p>
				<input className="textField" type="email" placeholder="E-MAIL" onChange={(e) => onTxtFieldChange(e)} onKeyPress={keyPressed}></input>
				<p className="errMessage" style={{display: err === "" ? "none" : ""}}>{err}</p>
				<p className="nextStep" style={{display: succesMailSub ? "" : "none"}}>CHECK YOUR MAILS TO CONFIRM YOUR EMAIL</p>
				<button type="submit" className="subBut" onClick={succesMailSub ? onCancelClick : handleValidation} style={{marginTop: succesMailSub ? "1.75rem" : err === "" ? "3.1875rem"  : "2.25rem"}}>
					{ succesMailSub ? "DONE" : "SUBSCRIBE"}
				</button>
			</div>

			<div className="overlay" onClick={(e) => onOverlayClick(e)}></div>
		</div>
	)
}

export default NewsletterAlert