import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Submit from "./SUBMIT/submit"
import Accept from "./LASTSTEP/LastStep"
import Page404 from "./404/404"
import ThankYou from "./THANKYOU/ThankYou"
import "./App.css"


function App() {
	
	return(
		<Router>
			<Switch>
				<Route path="/" exact component={Submit} />
				<Route path="/laststep=:id" exact component={Accept} />
				<Route path="/ThankYou-:id" exact component={ThankYou} />
				<Route path="*" component={Page404} />
			</Switch>
		</Router>
	)
}

export default App
