import React, {useEffect, useState} from 'react'
import HeaderFooter from "../HELPER/HeaderFooter"
import NavigationBar from '../HELPER/NavigationBar.js'
import Footer from "../HELPER/Footer"
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/BarLoader";
import "./LastStep.css"


const override = css`
  display: block;
  margin: 80px 0 0 0;
  border-color: red;
`;


function LastStep(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1200 ? true : false) // 1290 normal
	const [isLocked, setIsLocked] = useState(true)

	const [isLoading, setIsLoading] = useState(false)
	const [status,setStatus] = useState('')

	const [paypalMe, setPaypalMe] = useState("")
	const [userID, setUserID] = useState("")
	const [checkbox1, setCheckbox1] = useState(false)
	const [checkbox2, setCheckbox2] = useState(false)
	const [checkbox3, setCheckbox3] = useState(false)

	const [showTxtFieldMsg, setShowTxtFieldMsg] = useState(false)
	const [showTxtCheckboxMsg, setShowTxtCheckboxdMsg] = useState(false)


	useEffect(() => {
		document.title = "Infinit Tag | Last Step"

		let isAccessDenied = true

		for (let i = 0; i < 10; i++) {
			if (!isAccessDenied) {break}

			for (let i2 = 0; i2 < 10; i2++) {
				if (props.match.params.id === `AC-7253${i}${i2}13X`) {
					isAccessDenied = false
					break
				}
			}
		}

		if (isAccessDenied) { props.history.push("/404"); } else { setIsLocked(false) }

		setUserID(props.match.params.id)

		// eslint-disable-next-line
	}, [props.match.params.id])

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1200) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 




	// Submit Action
	const encode = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach((k)=>{
			formData.append(k,data[k])
		});
		return formData
	}

	const handleSubmit = e => {
		let data = { "form-name": "lastStep", paypalMe, checkbox1, checkbox2, checkbox3, userID }

		if (paypalMe === "" || checkbox1 === false || checkbox2 === false) {
			if (paypalMe === "") {setShowTxtFieldMsg(true)}
			else {setShowTxtFieldMsg(false)}
			if (checkbox1 === false || checkbox2 === false) {setShowTxtCheckboxdMsg(true)}
			else {setShowTxtCheckboxdMsg(false)}
			
			e.preventDefault();
			return
		} 
		
		setStatus("")
		setIsLoading(true)
		
		fetch("/", {
			method: "POST",
			// headers: { "Content-Type": 'multipart/form-data; boundary=random' },
			body: encode(data)
		})
		.then(() => { 
			setIsLoading(false)
			props.history.push("/ThankYou-LastStep") 
		}) 
		.catch(error => {
			setIsLoading(false)
			setStatus("Form Submission Failed! Try again later.")
		});

		e.preventDefault();
	};



	// On Change
	const handleChange = e => {
		const {name, value} = e.target

		if (name === 'paypalMe' ){ return setPaypalMe(value) }
		if (name === 'checkbox1' ){ return setCheckbox1(e.target.checked) }
		if (name === 'checkbox2' ){ return setCheckbox2(e.target.checked) }
		if (name === 'checkbox3' ){ return setCheckbox3(e.target.checked) }
	}



	if (isLocked) { return ("") }

	return(
		<div className="Last-Step">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div className="whole-container">
				<div className="wrapper">
					<h1>LAST STEP</h1>
					<h2>PAYOUT VIA PAYPAL</h2>
					<p className="paypalme">We need your PayPal.Me link to ensure that you have an active PayPal account. If you do not have a PayPal.Me link create one <a href="https://www.paypal.com/en/webapps/mpp/paypal-me" target="_blank" rel="noopener noreferrer">here</a>.</p>

					<form className="form" onSubmit={handleSubmit}>

						<div>
							<h3 className="input-header-insta">PAYPAL.ME LINK</h3>
							<input className="textField-paypal" type="text" name="paypalMe" value={paypalMe} placeholder="PAYPAL.ME LINK" onChange={handleChange}></input>
							{showTxtFieldMsg ? <p className="emptyMessage">PLEASE COMPLETE THIS FIELD</p> : ""}
						</div>


						<p className="checkbox-header-1">Your consent that we can use your picture </p>
						<div className="checkbox-conatiner-1">
							<input className="checkbox" type="checkbox" name="checkbox1" id="c1" value={checkbox1} onClick={handleChange} />
							<label for="c1"><span></span>You hereby declare your consent to use and publish the image on our website and in our mobile app. You also declare that you own the full rights for the image you have sent us.</label>
						</div>
						<div className="checkbox-conatiner-2">
							<input className="checkbox" type="checkbox" name="checkbox2" id="c2" value={checkbox2} onClick={handleChange} />
							<label for="c2"><span></span>Herewith you confirm that you are over 18 years old.</label>
						</div>
						{showTxtCheckboxMsg ? <p className="emptyMessage">YOU MUST AGREE TO THE TERMS AND CONDITIONS TO CONTINUE</p> : ""}

						<p className="checkbox-header-2">GET FETURED ON OUR INSTAGRAM PROFIL (OPTIONAL)</p>
						<div className="checkbox-conatiner-1">
							<input className="checkbox" type="checkbox" name="checkbox3" id="c3" value={checkbox3} onChange={handleChange}/>
							<label for="c3"><span></span>You agree that we may publish your image on our Instagram page.</label>
						</div>

 						{isLoading ?
							<ClipLoader
								css={override}
								size={"15px"} 
								color={"#000"}
								loading={isLoading}
								className="loading"
							/>
						: 
							<button className="sendBut" type="submit" >SEND</button> 
						}


						<input type="text" name="userID" style={{display: "none"}} value={`${props.match.params.id}`}/>
					</form>
					

					<p className="status">{status}</p>

					<p className="contact">IF YOU HAVE ANY QUESTIONS, <a href="mailto:original@honk.international">CONTATC US</a>.</p>
				</div>
			</div>

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
		</div>
	)
}

export default LastStep
