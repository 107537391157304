import React, {useEffect, useState} from 'react'
import HeaderFooter from "../HELPER/HeaderFooter"
import NavigationBar from '../HELPER/NavigationBar'
import Footer from "../HELPER/Footer"
import {Link} from 'react-router-dom'
import "./ThankYou.css"


function ThankYou(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1100 ? true : false) // 1290 normal


	useEffect(() => {
		if (props.match.params.id !== "LastStep" && props.match.params.id !== "Submit") { 
			props.history.push("/404") 
			return
		}

		document.title = "Infinit Tag | Thank You"

		// eslint-disable-next-line
	}, [props.match.params.id])

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1100) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	}

	return(
		<div className="ThankYou">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div className="content">
				<div className="wrapper">
					{ props.match.params.id === "Submit" ?
						<div className="h">
							<h1 className="submit-h1">THANK YOU!</h1>
							<p>WE LOOK AT YOUR PICTURE AND GET BACK TO YOU.</p>
							<Link className="more" to="/">SUBMIT ANOTHER ONE</Link>
						</div>
					:
						<div className="h">
							<h1 className="last-h1">GREAT!</h1>
							<h2>YOU ARE NOW PART OF OUR COLLECTION.</h2>
							<p>YOU'LL RECIEVE YOUR MONEY WITHIN THE NEXT FEW DAYS.</p>
							<Link className="more" to="/">SUBMIT ANOTHER ONE</Link>
						</div>
					}
					<p className="contact">IF YOU HAVE ANY QUESTIONS, <a href="mailto:original@honk.international">CONTACT US</a>.</p>
				</div>
			</div>

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
		</div>
	)
}

export default ThankYou