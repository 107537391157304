import React, {useState, useEffect} from "react"
import "./HeaderFooter.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faInstagram, faFacebookF, faApple} from "@fortawesome/free-brands-svg-icons"

function HeaderFooter(props) {
	
	const [isAtBottom, setIsAtBottom] = useState(props.data.actAn ? false : true)


	useEffect(() => {
		console.log(document.documentElement.offsetHeight, window.innerHeight)

		if (props.data.actAn) {		
			if (isAtBottom === 0) setIsAtBottom(false)
			else handleScroll()

			window.addEventListener("scroll", handleScroll);
		} else {
			setIsAtBottom(true)
		}
	
		return () => window.removeEventListener("scroll", handleScroll) 

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAtBottom])


	// Scroll Listener
	const handleScroll = () => {
		// console.log(document.documentElement.offsetHeight - window.innerHeight, window.pageYOffset + 40)
		if (document.documentElement.offsetHeight - window.innerHeight <= (window.pageYOffset + 40)) {
			// console.log(document.documentElement.offsetHeight - window.innerHeight, window.pageYOffset + 40)
			console.log("at bottom")
			if (!isAtBottom) { setIsAtBottom(true) }
		} else {
			if (isAtBottom) { setIsAtBottom(false) }
		}
	}


	// Clear Loacal Stoage
	const clearStorage = () => {
		try { localStorage.clear() } 
		catch(e) { console.log("get item or clear local storage failed: ", e) }
	}



	// On Click
	const onSozialClick = (url) => {
		window.open(url, "_blank")
	}


	// Footer Style
	const termsStyles = {
		transition: props.data.actAn ? ".45s" : "",
		WebkitTransition: props.data.actAn ? ".45s" : "",
		transform: isAtBottom ? "translateX(0px)" : "translateX(-40px)",
		WebkitTransform: isAtBottom ? "translateX(0px)" : "translateX(-40px)",
		opacity: isAtBottom ? "1" : "0"
	}

	// sozial Style
	const sozialStyles = {
		transition: props.data.actAn ? ".55s" : "",
		WebkitTransition: props.data.actAn ? ".55s" : "",
		transform: isAtBottom ? "translateY(0px)" : "translateY(40px)",
		WebkitTransform: isAtBottom ? "translateY(0px)" : "translateY(40px)",
		opacity: isAtBottom ? "1" : "0"
	}

	// appStore Style
	const appStoreStyles = {
		transition: props.data.actAn ? ".65s" : "",
		WebkitTransition: props.data.actAn ? ".65s" : "",
		transform: isAtBottom ? "translateY(0px)" : "translateY(40px)",
		WebkitTransform: isAtBottom ? "translateY(0px)" : "translateY(40px)",
		opacity: isAtBottom ? "1" : "0"
	}


	return (
		<div>
			<header className="Header">
				<h1 className="brand"><a href="https://infinittag.de" onClick={clearStorage}>INFINIT TAG</a></h1>

				<div className="choose-container">
					<a className="gender" href="https://infinittag.de/women" onClick={clearStorage}>WOMEN</a>
					<a className="gender" href="https://infinittag.de/men" onClick={clearStorage}>MEN</a>
				</div>
			</header> 


			<footer className="headerFooter">
				<div className="Terms">
					<a className="info" href="https://infinittag.de/contact" onClick={clearStorage} style={termsStyles}>CONTACT</a>
					<a className="info" href="https://infinittag.de/impressum" style={termsStyles} onClick={clearStorage}>LEGAL NOTICE </a>
					<a className="info" href="https://infinittag.de/datenschutz" style={termsStyles} onClick={clearStorage}>PRIVACY DISCLAIMER</a>
				</div> 


				{/* Sozial */}
				<div className="sozial-conatiner">
					<div className="sozialIcon-container" style={sozialStyles}>
						<button className="facebookBut" onClick={() => onSozialClick("https://www.facebook.com/HONKInternational/")}> <FontAwesomeIcon className="facebookIcon" icon={faFacebookF} /> </button> 
						<button className="instagramBut" onClick={() => onSozialClick("https://www.instagram.com/infinittag/")}> <FontAwesomeIcon className="instagramIcon" icon={faInstagram} />  </button> 
					</div>
					<a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="appStore" style={appStoreStyles}>
						<FontAwesomeIcon className="apple" icon={faApple} />
						<p>APP STORE</p>
					</a>
				</div>
			</footer>
		</div>
	)
}

export default HeaderFooter