import React, {useState} from 'react'
import "./Footer.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faInstagram, faFacebookF, faApple} from "@fortawesome/free-brands-svg-icons"
import NewstletterAlert from "./NewsletterAlert"
import { fstat } from 'fs'


// !! isSimilareItems should be true / top layer should have an bottom padding


function Footer(props) {

	const [isEmailAlertOpen, setIsEmailAlertOpen] = useState(false)

	const emailListCallback = () => {
		setIsEmailAlertOpen(fstat)
	}

	return (
		<div className="Footer" style={{position: props.isSimilareItems ? "absolute": ""}}> 
			<footer>
				<div className="terms">
					<a className="footerLinks" href="https://infinittag.de/contact">CONTACT</a>
					<a className="footerLinks" href="https://infinittag.de/impressum">LEGAL NOTICE</a> 
					<a className="footerLinks" href="https://infinittag.de/datenschutz">PRIVACY DISCLAIMER</a>
					<button className="footerLinks" onClick={() => setIsEmailAlertOpen(true)}>JOIN NEWSLETTER</button>
				</div>

				<div className="promo">
					<div className="socialList">
						<a href="https://www.facebook.com/HONKInternational/" target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon className="icon" icon={faFacebookF} /> </a> 
						<a href="https://www.instagram.com/infinittag/" target="_blank" rel="noopener noreferrer" className="instagram"> <FontAwesomeIcon className="icon" icon={faInstagram} />  </a> 
					</div>

					<a href="https://www.instagram.com/infinittag/" target="_blank" rel="noopener noreferrer" className="appStoreBut">
						<p>
							<FontAwesomeIcon className="apple" icon={faApple} />
							APP STORE
						</p>
					</a>
				</div>
			</footer>


			<NewstletterAlert states={{
				isOpen: isEmailAlertOpen,
				setBackToHidden: emailListCallback
			}}/>
		</div>
	)
}

export default Footer