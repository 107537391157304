import React, {useState, useEffect, useRef} from 'react'
import './NavigationBar.css'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

function NavigationBar(props) {

	const [isWindowAtTop, setIsWindowAtTop] = useState(true)
	// const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	// const isSafari = navigator.userAgent.indexOf("Safari") !== -1

	// phone Menu Icon
	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1200 ? true : false)

	// Ref
	const overlayRef = useRef(null)

	// first render
	const [isFirstRender, setIsFirstRender] = useState(true)


	// 
	useEffect(() => {
		if (props.states.isInCollection) setIsWindowAtTop(window.pageYOffset > 50 ? false : true)

		if (props.states.isInCollection) window.addEventListener("scroll", handleScroll)
		else setIsWindowAtTop(false)
		window.addEventListener("resize", handleResize)

		return () => { 
			window.removeEventListener("scroll", handleScroll) 
			window.removeEventListener("resize", handleResize) 
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps	
	}, [isWindowAtTop, isDesktop, props])


	// Event Listeners
	// Scroll
	const handleScroll = () => {
		if (window.pageYOffset > 50) {
			if (isWindowAtTop) { setIsWindowAtTop(false) }
		} else {
			if (!isWindowAtTop) { setIsWindowAtTop(true) }
		}
	}

	// Windows Resize
	const handleResize = () => {
		if (window.innerWidth > 1200) {
			if (!isDesktop) setIsDesktop(true)
		} else {
			if (isDesktop) setIsDesktop(false)
		}
	}


	// Clear Loacal Stoage
	const clearStorage = () => {
		try { localStorage.clear() } 
		catch(e) { console.log("get item or clear local storage failed: ", e) }
	}


	// on click Link
	const onClickLink = (gender) => {
		clearStorage()
		closeOpenMenu()
		if (gender === props.states.gender && props.states.isInCollection) window.location.reload()
	}

	// Menu Icon Click
	const closeOpenMenu = () => {
		if (isMenuOpen) clearAllBodyScrollLocks()
		else disableBodyScroll(overlayRef.current)

		setIsMenuOpen(!isMenuOpen)
	}

	// Overlay
	const onOverlayClick = (e) => {
		if (e.target.className === "overlayBack") { closeOpenMenu() }
	}

	// Styles
	const navStyles = {
		// padding: isWindowAtTop ? `${isDesktop ? 51 : 31}px 0` : `${isDesktop ? 24 : 18}px 0`,
		padding: isWindowAtTop ? `${!isDesktop && props.states.isInCollection ? 31 : 51}px 0` : `${!isDesktop && props.states.isInCollection ? 18 : 24}px 0`,
		animation: isWindowAtTop ? "colorchangeBack 0.5s forwards" : props.states.isInCollection ? "colorchange 0.5s forwards" : "",
		WebkitAnimation: isWindowAtTop ? "colorchangeBack 0.5s forwards" : props.states.isInCollection ? "colorchange 0.5s forwards" : "",
		backgroundColor: props.states.isInCollection ? "" : "white"
	}

	const headerStylesWomen = {
		color: props.states.gender === "women" ? "#282828" : props.states.gender === "men" ? "#696969" : ""
	}

	const headerStylesMen = {
		color: props.states.gender === "men" ? "#282828" : props.states.gender === "women" ? "#696969" : ""
	}
	
	// Phone
	// overlay Manu
	const menuOverlayStyles = {
		maxHeight: isMenuOpen ? `122px` : "0px"
	}

	const listStylesWomen = {
		top: isMenuOpen ? "0px" : "-21px",
		color: props.states.gender === "women" ? "#282828" : props.states.gender === "men" ? "#8D8D8D" : ""
	}

	const listStylesMen = {
		top: isMenuOpen ? "0px" : "-23px",
		color: props.states.gender === "men" ? "#282828" : props.states.gender === "women" ? "#8D8D8D" : ""
	}

	const overlayBackStyles = {
		height: isMenuOpen ? "100vh" : "0",
		backgroundColor: isMenuOpen ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"
	}

	return (
		<header>
			<div className="biggerNav-container" style={navStyles}>
				<nav>
					<h1 className="brand"> <a href="infinittag.de">INFINIT TAG</a> </h1>
					<ul>
						<li> <a className="chooseGender" href="https://infinittag.de/women" style={headerStylesWomen}>WOMEN</a> </li>
						<li> <a className="chooseGender" href="https://infinittag.de/men" style={headerStylesMen}>MEN</a> </li>
					</ul>
				</nav>
			</div>

			<div className="phoneNav-container">
				<nav>
					<button className="menuIcon" onClick={closeOpenMenu}>
						<div className="icon-container">
							<div className={`topLayer${isMenuOpen === true ? "-open" : ""}`}></div>
							<div className={`bottomLayer${isMenuOpen === true ? "-open" : ""}`}></div>
						</div>
					</button>

					<h1 className="brand"><a href="https://infinittag.de"onClick={clearAllBodyScrollLocks}>INFINIT TAG</a></h1>
					
					{props.states.shouldDiaplayShare === true ?
						<div className="share">
							<button className="button" onClick={() => props.states.onShareCallack(true)}>
							</button>
						</div>
					: "" }
				</nav>
			</div>

			<div className="phoneMenuOverlay" style={menuOverlayStyles}>
				<div className="background" ref={overlayRef}>
					<a className="text" href="https://infinittag.de/women" onClick={() => onClickLink("women")} style={listStylesWomen}>WOMEN</a>
					<a className="text" href="https://infinittag.de/men" onClick={() => onClickLink("men")} style={listStylesMen}>MEN</a>
				</div>
			</div>
			<div className="overlayBack" style={overlayBackStyles} onClick={(e) => onOverlayClick(e)}></div>

			{isFirstRender ? setIsFirstRender(false) : ""}
		</header>
	)
}

export default NavigationBar