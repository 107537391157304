import React, {useEffect, useState, useCallback} from 'react'
import HeaderFooter from "../HELPER/HeaderFooter"
import NavigationBar from '../HELPER/NavigationBar.js'
import Footer from "../HELPER/Footer"
import {useDropzone} from 'react-dropzone'
import closeIcon from "./CloseIcon.svg"
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/BarLoader";
import "./submit.css"

//https://www.davidhu.io/react-spinners/

const override = css`
  display: block;
  margin: 50px 0 0 0;
  border-color: red;
`;

function Submit(props) {
	const [isDesktopNav, setIsDesktopNav] = useState(window.innerWidth > 1200 ? true : false) // 1290 normal
	const [outfitCounter, setOutfitCounter] = useState([0])
	const [isLoading, setIsLoading] = useState(false)

	const [firstName,setFirstName] = useState('')
	const [lastName,setLastName] = useState('')
	const [email,setEmail] = useState('')
	const [clothingType1, setClothingTyp1] = useState("Jacket")
	const [clothingType2, setClothingTyp2] = useState("Jacket")
	const [clothingType3, setClothingTyp3] = useState("Jacket")
	const [clothingType4, setClothingTyp4] = useState("Jacket")
	const [clothingType5, setClothingTyp5] = useState("Jacket")
	const [clothingType6, setClothingTyp6] = useState("Jacket")
	const [clothingType7, setClothingTyp7] = useState("Jacket")
	const [clothingType8, setClothingTyp8] = useState("Jacket")
	const [brand1, setBrand1] = useState("")
	const [brand2, setBrand2] = useState("")
	const [brand3, setBrand3] = useState("")
	const [brand4, setBrand4] = useState("")
	const [brand5, setBrand5] = useState("")
	const [brand6, setBrand6] = useState("")
	const [brand7, setBrand7] = useState("")
	const [brand8, setBrand8] = useState("")
	const [link1, setLink1] = useState("")
	const [link2, setLink2] = useState("")
	const [link3, setLink3] = useState("")
	const [link4, setLink4] = useState("")
	const [link5, setLink5] = useState("")
	const [link6, setLink6] = useState("")
	const [link7, setLink7] = useState("")
	const [link8, setLink8] = useState("")
	const [file, setFile] = useState({})
	const [status,setStatus] = useState('')

	const [showEmptyFirstName, setShowEmptyFirstName] = useState(false)
	const [showEmptyLastName, setShowEmptyLastName] = useState(false)
	const [showEmptyEmail, setShowEmptyEmail] = useState(false)
	const [showEmptyOutfits, setShowEmptyOutfits] = useState(false)
	const [showEmptyImage, setShowEmptyImage] = useState(false)


	// Drag & Drop 
	const onDrop = useCallback(acceptedFiles => {
		if (showEmptyImage) {setShowEmptyImage(false)}

		if (acceptedFiles.length > 1) { setStatus("you can only upload one picture.") }
		else { setStatus("") }

		setFile(acceptedFiles[0])
	}, [showEmptyImage])
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


	// Use Effect
	useEffect(() => {
		document.title = "Infinit Tag | Submit Outfit"
	}, [])

	// Event Listener
	useEffect(() => {
		window.addEventListener("resize", handleWindowSize) 
		return () => window.removeEventListener("resize", handleWindowSize)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktopNav])

	const handleWindowSize = () => {
		if (window.innerWidth > 1200) {
			if (!isDesktopNav) setIsDesktopNav(true)
		} else {
			if (isDesktopNav) setIsDesktopNav(false)
		}
	} 



	// Submit Action
	const encode = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach((k)=>{
			formData.append(k,data[k])
		});
		return formData
	}

	const handleSubmit = e => {
		let data = {}

		if (outfitCounter.length === 1) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, brand1, link1, file } }
		else if (outfitCounter.length === 2) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, brand1, brand2, link1, link2, file } }
		else if (outfitCounter.length === 3) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, brand1, brand2, brand3, link1, link2, link3, file } }
		else if (outfitCounter.length === 4) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, clothingType4, brand1, brand2, brand3, brand4, link1, link2, link3, link4, file } }
		else if (outfitCounter.length === 5) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, clothingType4, clothingType5, brand1, brand2, brand3, brand4, brand5, link1, link2, link3, link4, link5, file } }
		else if (outfitCounter.length === 6) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, clothingType4, clothingType5, clothingType6, brand1, brand2, brand3, brand4, brand5, brand6, link1, link2, link3, link4, link5, link6, file } }
		else if (outfitCounter.length === 7) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, clothingType4, clothingType5, clothingType6, clothingType7, brand1, brand2, brand3, brand4, brand5, brand6, brand7, link1, link2, link3, link4, link5, link6, link7, file } }
		else if (outfitCounter.length === 8) { data = { "form-name": "submitOutfit", firstName, lastName, email, clothingType1, clothingType2, clothingType3, clothingType4, clothingType5, clothingType6, clothingType7, clothingType8, brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8, link1, link2, link3, link4, link5, link6, link7, link8, file } }
		else { 
			setStatus("You can only point to 8 clothes.")
			e.preventDefault();
			return
		}

		if (firstName === "" || lastName === "" || email === "" || Object.entries(file).length === 0 || outfitCounter.length < 3) {
			if (firstName === "") {setShowEmptyFirstName(true)}
			else {setShowEmptyFirstName(false)}

			if (lastName === "" ) {setShowEmptyLastName(true)}
			else {setShowEmptyLastName(false)}

			if (email === "" ) {setShowEmptyEmail(true)}
			else {setShowEmptyEmail(false)}

			if (outfitCounter.length < 3) {setShowEmptyOutfits(true)}
			else {setShowEmptyOutfits(false)}

			if (Object.entries(file).length === 0) {setShowEmptyImage(true)}
			else {setShowEmptyImage(false)}
			
			e.preventDefault();
			return
		} 

		setStatus("")
		setIsLoading(true)

		
		fetch("/", {
			method: "POST",
			// headers: { "Content-Type": 'multipart/form-data; boundary=random' },
			body: encode(data)
		})
		.then(() => { 
			setIsLoading(false)
			props.history.push("/ThankYou-Submit") 
		}) 
		.catch(error => {
			setIsLoading(false)
			setStatus("Form Submission Failed! Try again later.")
		});

		e.preventDefault();
	};

	const handleChange = e => {
		const {name, value} = e.target

		if (name === 'firstName' ){ return setFirstName(value) }
		if (name === 'lastName' ){ return setLastName(value) }
		if (name === 'email' ){ return setEmail(value) }
		if (name === 'clothingType1' ){ return setClothingTyp1(value) }
		if (name === 'clothingType2' ){ return setClothingTyp2(value) }
		if (name === 'clothingType3' ){ return setClothingTyp3(value) }
		if (name === 'clothingType4' ){ return setClothingTyp4(value) }
		if (name === 'clothingType5' ){ return setClothingTyp5(value) }
		if (name === 'clothingType6' ){ return setClothingTyp6(value) }
		if (name === 'clothingType7' ){ return setClothingTyp7(value) }
		if (name === 'clothingType8' ){ return setClothingTyp8(value) }
		if (name === 'brand1' ){ return setBrand1(value) }
		if (name === 'brand2' ){ return setBrand2(value) }
		if (name === 'brand3' ){ return setBrand3(value) }
		if (name === 'brand4' ){ return setBrand4(value) }
		if (name === 'brand5' ){ return setBrand5(value) }
		if (name === 'brand6' ){ return setBrand6(value) }
		if (name === 'brand7' ){ return setBrand7(value) }
		if (name === 'brand8' ){ return setBrand8(value) }
		if (name === 'link1' ){ return setLink1(value) }
		if (name === 'link2' ){ return setLink2(value) }
		if (name === 'link3' ){ return setLink3(value) }
		if (name === 'link4' ){ return setLink4(value) }
		if (name === 'link5' ){ return setLink5(value) }
		if (name === 'link6' ){ return setLink6(value) }
		if (name === 'link7' ){ return setLink7(value) }
		if (name === 'link8' ){ return setLink8(value) }
	}





	const onAddOutfitClick = () => {
		if (outfitCounter.length >= 8) {
			setStatus("You can only point to 8 clothes.")
			return
		}

		if (showEmptyOutfits) {setShowEmptyOutfits(false)}

		let newArr = []
		for (let i in outfitCounter) { newArr.push(parseInt(i)) }
		newArr.push(outfitCounter.length)
		console.log("––––––––––––––––", newArr)
		setOutfitCounter(newArr)
	}


	const removeFile = () => {
		setFile({})
	}




	const outfits = () => {
		const clothingTypes = [clothingType1, clothingType2, clothingType3, clothingType4, clothingType5, clothingType6, clothingType7, clothingType8]
		const brands = [brand1, brand2, brand3, brand4, brand5, brand6, brand7, brand8]
		const links = [link1, link2, link3, link4, link5, link6, link7, link8]

		return outfitCounter.map(id => 
			<span className="clothing-wrapper" key={`${id}`}>
				<select className="select" name={`clothingType${id + 1}`} value={clothingTypes[id]} onChange={handleChange}>
					<option value="jacket">JACKET</option>
					<option value="hoodie">HOODIE</option>
					<option value="shirt">SHIRT</option>
					<option value="pants">PANTS</option>
					<option value="shoes">SHOES</option>
					<option value="accessoires">ACCESSOIRES</option>
					<option value="other">OTHER</option>
				</select>
				<input className="textField-brand" type="text" name={`brand${id + 1}`} value={brands[id]} onChange={handleChange} placeholder="BRAND"></input>
				<input className="textField-link" type="text" name={`link${id + 1}`} value={links[id]} onChange={handleChange} placeholder="LINK"></input>
			</span>
		)
	}

	const imgFiles = () => {
		if (file.name !== undefined) {
			return ( 
				<div className="fileName-container">
					<p >{file.name}</p>
					<button className="removeButton" type="button" onClick={removeFile}>
						<div className="icon-container">
							<img className="closeIcon" src={closeIcon} alt="close icon"></img>
						</div>
					</button>
				</div>
			)
		}
		return ""
	}

	return(
		<div className="Submit">
			{isDesktopNav ? <HeaderFooter data={{actAn: false}}/> : <NavigationBar states={{isInCollection: false} }/>}

			<div className="whole-container">
				<div className="wrapper">
					<h1>SUBMIT YOUR OUTFIT</h1>
					<form className="form" onSubmit={handleSubmit}>
						<div className="block-1">
							<div className="input">
								<p className="input-header">FIRST NAME</p>
								<input className="textField" type="text" name="firstName" placeholder="FIRST NAME" value={firstName} onChange={handleChange}></input>
								{showEmptyFirstName ? <p className="emptyMessage">PLEASE COMPLETE THIS FIELD</p> : ""}
							</div>
							<div className="input">
								<p className="input-header" id="input-header-last">LAST NAME</p>
								<input className="textField" type="text" name="lastName" placeholder="LAST NAME" value={lastName} onChange={handleChange}></input>
								{showEmptyLastName ? <p className="emptyMessage">PLEASE COMPLETE THIS FIELD</p> : ""}
							</div>
							<div className="input"> 
								<p className="input-header-mail">E-MAIL</p>
								<input className="textField" type="email" name="email" placeholder="E-MAIL" value={email} onChange={handleChange}></input>
								{showEmptyEmail ? <p className="emptyMessage">PLEASE COMPLETE THIS FIELD</p> : ""}
							</div>
						</div>
						<div className="block-2">
							<p className="input-header">OUTFIT ITEMS</p>
							<div className="clothes">
								{outfits()}
								
								{outfitCounter.length < 8 ? 
									<button className="addOutfit" onClick={onAddOutfitClick} type="button">
										<div className="icon-container">
											<div className="icon-1"></div>
										</div>
										<div className="icon-container">
											<div className="icon-2"></div>
										</div>
									</button>
								: "" }

								{showEmptyOutfits ? <p className="emptyMessage">PLEASE LINK ALL YOUR OUTFITS</p> : ""}
							</div>
						</div>
						<div className="block-4">
							{/* <input className="chooseFile" type="file" name="file" id="file" accept="image/*" 
							// <input className="chooseFile" type="file" name="imageFile" id="file" 
									data-multiple-caption={"message"}
									multiple
									onChange={(e) => getUploadedFileName(e)}
									ref={imagePickerRef}>
							</input>
							<label for="file">Choose a file</label> */}



							<div {...getRootProps()} className="drop-container">
								<input className="chooseFile" {...getInputProps()} accept="image/*"/>
								<label style={{backgroundColor: isDragActive ? "#DEDEDE" : "#F2F2F2"}}>{isDragActive ? "Drop the files here ..." : "Choose a file or drag 'n' drop"}</label>
							</div>

							{showEmptyImage ? <p className="emptyMessage">please upload a picture</p> : ""}


							{imgFiles()}
						</div>

						<button className="submitBut" type="submit" style={{display: isLoading ? "none" : ""}}>SUBMIT</button>
						{isLoading ? <ClipLoader
							css={override}
							size={"15px"} 
							color={"#000"}
							loading={isLoading}
							className="loading"
						/> : ""}
					</form>

					<p className="status">{status}</p>
				</div>
			</div>

			

			{isDesktopNav ? "" : <Footer isSimilareItems={true}/>}
		</div>
	)
}

export default Submit
